<template>
  <div class="album-container">
    <CoolLightBox 
      :items="items" 
      :index="index"
      @close="index = null">
    </CoolLightBox>
    <div class="album">
      <div v-for="(image, i) in images" class="photo-container" :key="i" v-bind:class="{bigPhoto: i===3}">
        <a href="#" @click.prevent="index = i">
          <img :src="hostUrl+image.url" :alt="`Property Photo ${i}`" >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
    name: "Album",
    components:{
      CoolLightBox
    },
    props:['images'],
    data(){
      return {
        hostUrl: 'https://strapi.uptowncreative.io',
        index: null,
      }
    },
    computed:{
      items(){
        const test = this.images.map((item) => {
          return this.hostUrl + item.url;
        })
        return test;
      }
    }
}
</script>

<style lang="scss" scoped>
.album-container{
  position: relative;
}
.album{
  display: flex;
  flex-flow: wrap;
  .photo-container{
    padding: 5px;
    width: 33.33%;
    a{
      display: block;
      img{
        display: block;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .bigPhoto{
    width: 100%;
  }
}
</style>
